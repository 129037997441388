<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-form>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Hệ đào tạo"
                  label-for="trainingSystem"
                >
                  <v-select
                    id="trainingSystem"
                    v-model="filter.trainingSystemId"
                    label="name"
                    :options="trainingSystems"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Khóa học"
                  label-for="course"
                >
                  <v-select
                    id="course"
                    v-model="filter.courseId"
                    label="name"
                    :options="courses"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Ngành học"
                  label-for="major"
                >
                  <v-select
                    id="major"
                    v-model="filter.majorId"
                    label="name"
                    :options="majors"
                    :reduce="option => option.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Loại chứng chỉ"
                  label-for="subjectTypeCode"
                >
                  <v-select
                    id="subjectTypeCode"
                    v-model="filter.subjectTypeCode"
                    label="label"
                    :options="subjectTypeOptions"
                    :reduce="option => option.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="w-100 d-flex justify-content-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="getDataSourcesFromStore"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="SearchIcon" /> Tìm kiếm
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
          <b-row class="mt-2">
            <b-col cols="12">
              <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="dataSources"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                :line-numbers="true"
                @on-page-change="onPageChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>

                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Common -->
                  <span>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị 1 đến </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">của {{ dataSources.length }} bản ghi</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import vSelect from 'vue-select'
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getUser } from '@/auth/utils'
import { SUBJECT_TYPES } from '@/const/type'

export default {
  name: 'ReportCertification',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BOverlay,
    BButton,
    BCard,
    BForm,
    BFormSelect,
    BContainer,
    vSelect,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 20,
        trainingSystemId: null,
        courseId: null,
        majorId: null,
        subjectTypeCode: 'TC',
        studentCode: null,
        lastName: null,
        name: null,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã sinh viên',
          field: 'studentCode',
          filterOptions: {
            enabled: true,
            trigger: 'Nhập mã sinh viên',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Họ đệm',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            trigger: 'Nhập họ đệm',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'Nhập tên',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Lớp học',
          field: 'className',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngành học',
          field: 'majorName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Môn học',
          field: 'subjectName',
          sortable: false,
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'reportCertification/trainingSystems',
      courses: 'reportCertification/courses',
      majors: 'reportCertification/majors',
      dataSources: 'reportCertification/students',
      totalRows: 'reportCertification/totalRows',
    }),
    subjectTypeOptions() {
      return SUBJECT_TYPES
    },
  },
  watch: {
    'filter.trainingSystemId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getCoursesByTrainingSystemId({ trainingSystemId: val })
          if (this.courses.length > 0) {
            this.updateParams({ courseId: this.courses[0].id })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ courseId: null })
        this.setCourses([])
      }
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getTrainingSystemsByOrganizationId({
          organizationId: this.user.orgId,
        }),
        this.getMajorsByOrganizationId({
          organizationId: this.user.orgId,
        }),
      ])
      if (this.trainingSystems.length > 0) {
        this.updateParams({ trainingSystemId: this.trainingSystems[0].id })
      }
      if (this.majors.length > 0) {
        this.updateParams({ majorId: this.majors[0].id })
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setCourses: 'course/SET_COURSES',
    }),
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'reportCertification/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'reportCertification/getCoursesByTrainingSystemId',
      getMajorsByOrganizationId: 'reportCertification/getMajorsByOrganizationId',
      getCertificatedStudents: 'reportCertification/getCertificatedStudents',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getCertificatedStudents(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'studentCode')) this.updateParams({ studentCode: columnFilters.studentCode })
      if (Object.hasOwn(columnFilters, 'lastName')) this.updateParams({ lastName: columnFilters.lastName })
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      await this.getDataSourcesFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
